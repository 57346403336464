<template>
  <div>
    <navbar title="خانه" route='/' />
    <v-main>
      <v-row class="pa-1">
        <v-col @click="change_page('wallet')" class="d-flex flex-column align-center justify-center">
          <div>
            <v-img width="60" height="60" src="@/assets/icon/wallet.svg"></v-img>
          </div>
          <span class="medium-font">حساب های من</span>
        </v-col>
        <v-col @click="change_page('my_location')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/locations.svg"></v-img>
          <span class="medium-font">مکان های من</span>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center pa-1">
        <v-col @click="page('orders')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/bill.svg"></v-img>
          <span class="medium-font">سفارش های من</span>
        </v-col>
        <v-col @click="update" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/delivery-box.svg"></v-img>
          <span class="medium-font">بروزرسانی</span>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center pa-1">
        <v-col @click="change_page('contactUs')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/contact.svg"></v-img>
          <span class="medium-font">تماس باما</span>
        </v-col>
        <v-col @click="change_page('aboutUs')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/about.svg"></v-img>
          <span class="medium-font">درباره ما</span>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center pa-1">
        <v-col @click="change_page('rules')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/rules.svg"></v-img>
          <span class="medium-font">قوانین و مقررات</span>
        </v-col>
        <v-col @click="change_page('inviteFriends')" class="d-flex flex-column align-center justify-center">
          <v-img width="60" height="60" src="@/assets/icon/mails.svg"></v-img>
          <span class="medium-font">دعوت از دوستان</span>
        </v-col>
      </v-row>
      <!-- <v-row class="d-flex align-center justify-center pa-1">
      <v-col @click="change_page('/my_orders')" class="d-flex flex-column align-center justify-center">
				<v-row class="d-flex align-center justify-center pa-1">
					<v-col @click="change_page('/bookmark')" class="d-flex flex-column align-center justify-center">
            <v-img width="60" height="60" src="@/assets/icon/folder.svg"></v-img>
            <span class="medium-font">علاقه مندی ها</span>
					</v-col>
					<v-col @click="update" class="d-flex flex-column align-center justify-center">
          </v-col>
      </v-row> -->
    </v-main>
  </div>
</template>
<script>
import router from '@/router'
import navbar from '@/components/navbar/appBar'
import { get_config } from '@/models/changeType'
import { viewbasket_products } from '../Basket/models/Basket'
import store from '@/store'
export default {
  components: {
    navbar
  },
  created() {
    const basket = localStorage.getItem('basket')
    if (basket) {
      const time = new Date()
      const now = time.getTime()
      const old_time_basket = parseInt(localStorage.getItem('time_basket'))
      if ((old_time_basket + 86400000) < now) {
        localStorage.removeItem('basket')
        viewbasket_products()
      } else {
        store.dispatch('basket_local', JSON.parse(basket))
      }
    } else {
      viewbasket_products()
    }
  },
  methods: {
    change_page(page) {
      router.push(`/apps/${page}`)
    },
    page(page) {
      router.push(`/${page}`)
    },
    update() {
      this.loading = true
      localStorage.removeItem('config')
      get_config()
      setTimeout(() => {
        this.loading = false
      }, 5000)
    }
  }
}
</script>